.browse-file {

  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  .thumbnail {
    width: 100%;
  }

  &-btn {
    width: 100%;
    background: #1A1A1A;
    padding: 15px;
    text-align: center;
    line-height: 1.1;
    color: #FFFFFF;
    margin-bottom: 35px;
    outline: none;
    border: none;
  }

  &-desc {
    color: #767676;

  }

  &-text-url {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    margin-top: 15px;

    input {
      border-top: 1px solid #B2B2B2;
      border-bottom: 1px solid #1A1A1A;
      border-right: 0;
      border-left: 1px solid #B2B2B2;
      width: 100%;
      padding: 10px 15px;
      outline: none;

      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }

    &-btn {
      padding: 9.5px;
      background: #1A1A1A;
      color: #fff;
      outline: none;
      border-top: 1px solid #1A1A1A;
      border-left: 0;
      margin: 0 !important;
    }
  }
}

.upload-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.40);
  cursor: pointer;
  
  img {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}