.liftStylePage{
  margin-top: 100px;
  margin-bottom: 60px;
  width: 100vw;
  height: calc(100vh - 100px - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgb(249,249,249);
  padding: 80px 90px;
  display: flex;
  flex-direction: row;
  .leftCol{
    width: 480px;
    min-height: 702px;
    background: white;
    margin-right: 20px;
    padding: 22px 24px 0 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    .title{
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      color: #000000
    }
    .textSub{
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #000000;
      margin-top: 30px;
    }
  }
  .rightCol{
    width: calc(100vw - 180px - 480px - 20px);
    background: white;
    min-height: 702px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    img{
      object-fit: cover;
    }
  }
}
