.modal-container {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20000;
  justify-content: center;
  align-items: center;
  display: flex;
  .modal-base-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-base-body {
      padding: 70px 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }

    .modal-base-body::-webkit-scrollbar {
      display: none;
    }

  }
  .btn-close-modal{
    position: absolute;
    right: 35px;
    top:35px;
    cursor: pointer;
    z-index: 999;
  }
}

