.wrap-bottom-navigation {
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0 ;
  width: 100%;
  z-index: 120;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  color: #000000;
  background: #ffffff;

  div {
    z-index: 1;
  }
  .text-black {
    color: #000000;
  }
  &::after {
    content: '';
    // background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) , transparent);
    height: 170px;
    width: 100%;
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .left-group-btn {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    font-family: sans-serif;

    .btn-item {
      padding: 0px 20px;
      display: block;
      cursor: pointer;
      svg {
        width: 24px;
      }
    }
  }

  .right-group-btn {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;

    .customer-name {
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
      margin-right: 12px;
    }

    .btn-item {
      padding: 0px 20px;
      font-weight: bold;
      font-family: sans-serif;
      font-size: 13px;
      cursor: pointer;
      svg {
        width: 24px;
      }
    }
  }

  &.hide {
    display: none;
  }

  .cursor {
    cursor: pointer;
  }
}
