.wrap-holding-page {
  background-size: cover;
  background-position: center;
  .modal-form {
    color: #000;
    font-size: 14px;
    width: 666px;
    background: #fff;
    padding: 36px 40px;
    box-shadow: -4px 5px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .modal-form__title {
    font-size: 24px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    max-width: 500px;
    &.underline-sm {
      &::before {
        width: 75px;
        background: #000;
        bottom: -9px;
        height: 1px;
      }
    }
  }
  .modal-form__subtitle {
    font-size: 16px;
    line-height: 2.188em;
    margin-bottom: 26px;
  }
  .modal-form__body .form-group {
    border: 1px solid #414345;
    border-style: dashed;
    margin-top: 10px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .modal-form__body .form-control {
    border-radius: 0;
    border: 0;
    height: 40px;
    padding: 6px 8px;
    line-height: 2;
    background: #fff;
    font-size: 14px;
    color: #414345;
    &:focus {
      box-shadow: none;
    }
  }
  .modal-form__body .btn-link {
    text-decoration: underline;
    font-family: 'Futura PT';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 100%;
    text-decoration-line: underline;
    color: #000000;
  }

  .holding-wrapper{
    width: 650px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 75px 0;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    .logo-brickell{
      width: 233px;
      height: 75px;
    }
    .content-login{
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      .title-login{
        color: #1A1A1A;
        font-family: 'Roos St Regis Text';
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
      }
      .login-form{
        margin-top: 89px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        width: 100%;
        .form-input{
          display: flex;
          flex-direction: column;
          span{
            color: rgba(26, 26, 26, 0.50);
            font-family: 'Suisse';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
          }
          input{
            margin-top: 8px;
            border: none;
            border-bottom: solid 1px #1A1A1A;
            background: transparent;
          }
          input::placeholder{
            color: #B2B2B2;
          }
          input:focus{
            outline: none;
          }
        }
        .form-input:last-child{
          margin-top: 33px;
        }
      }
    }
  }
}
