.multi-range-slider-container {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .slider {
    position: relative;
    width: 190px;
  }

  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }

  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
  }

  .slider__track {
    background-color: #E4E4E4;
    width: 100%;
    z-index: 1;
  }

  .slider__range {
    background-color: #000;
    z-index: 2;
  }

  .slider__left-value,
  .slider__right-value {
    color: #C2C2C2;
    font-size: 12px;
    margin-top: 10px;
  }

  .slider__left-value {
    left: 6px;
  }

  .slider__right-value {
    right: 0px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 196px;
    outline: none;
  }

  .thumb--left {
    z-index: 3;
  }

  .thumb--right {
    z-index: 4;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #000;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  @media screen and (max-width: 1025px) {
    /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    height: 28px;
    width: 28px;
    border-radius: 14px;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    height: 28px;
    width: 28px;
    border-radius: 14px;
   }
  }
}
