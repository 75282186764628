@import './common';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #E4E4E8;
  overflow: hidden;
}

.bg,
canvas {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: grab;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  background-color: #4e4d4b;
  font-family: $font-family-sans-serif;
}

.page {
  background-color: #eee;
  /* background-image: url("https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg"); */
  min-height: 100vh;
  /* background-repeat: no-repeat;
  background-size: 100% 100%; */
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888850;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #55555550;
}

/* fade in/out animation */
.fade-item-enter {
  opacity: 0;
}

.fade-item-enter-active {
  opacity: 1;
  transition: opacity 125ms ease-in;
}

.fade-item-exit {
  opacity: 1;
}

.fade-item-exit-active {
  opacity: 0;
  transition: opacity 125ms ease-in;
}

/* fade left animation */
.fade-left-enter {
  margin-left: -100%;
  opacity: 0;
}

.fade-left-enter-active {
  margin-left: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-left-exit {
  margin-left: 0;
  opacity: 1;
}

.fade-left-exit-active {
  margin-left: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

/* fade right animation */
.fade-right-enter {
  right: -100%;
  opacity: 0;
}

.fade-right-enter-active {
  right: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-right-exit {
  right: 0;
  opacity: 1;
}

.fade-right-exit-active {
  right: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

.rs-base.hide {
  display: none;
}

.static-page{
  margin-top: 80px;
  margin-bottom: 60px;
  width: 100vw;
  height: calc(100vh - 80px - 60px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgb(249,249,249);
  padding: 80px 90px;
  display: flex;
  flex-direction: row;
}

.static-page-wf {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100vw;
  //height: calc(100vh - 80px - 80px);
  height: 100dvh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: url('./assets/images/bg-holding.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: absolute;
  top: 0;
}

.static-page-wf::-webkit-scrollbar {
  display: none;
}

.bg-image-page{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 99.7%;
}

.bg-image-page-map{
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width:100vw;
  height: calc(100vw * 0.65);
  background-color: white;
  position: relative;
}

.basic-page{
  width: 100vw;
  height: 100dvh;
  padding: 80px 0;
  position: absolute;
  top: 0
}

.t-12{
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.w700{
  font-weight: 700;
}

.w600{
  font-weight: 600;
}

.space-bottom-50{
  height: 50px;
  width: 100%;
}

.hide-bottom{
  display: none;
}

.modal-backdrop {
  background-color: rgba(26, 26, 26, 0.75);
}
