.overplay-cms {

  .overplay-cms-title {
    margin-bottom: 50px;
  }

  &-component {
    display: none;

    &.active {
      display: block;
    }
  }

  .modal-content {
    top: -50px;
    padding: 35px !important;
    border-radius: 0 !important;
    width: 600px !important;
    max-height: 75vh;
    overflow-y: scroll;
  }
  &-title {
    font-family: "Suisse Int'l";
  }

  &-body {
    margin: 50px 0;

    &-border{
      width: 100%;
      border-top: 1px solid #E4E4E4;
      margin: 35px 0;
    }

    &.delete {
      margin: 20px 0 50px 0;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;

    &-delete {
      text-decoration: underline;
    }

    &-save {
      button {
        background: #1A1A1A;
        padding: 20px 50px;
        color: #fff;
        line-height: 1;
      }
    }
  }
}
