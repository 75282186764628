.image-choose {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    //position: absolute;
  }

  &:hover {
    .image-choose-icon-upload {
      display: block;
    }
  }


  &-icon-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
