.error-validate {
  color: #FF674C;
  /* Form text */
  font-family: "Suisse Int'l";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.gallery-cms{
  &-wrapper-input {

    border-bottom: 1px solid #1A1A1A;
    padding: 8px 0;

    &-container {
      padding: 0 16px;
    }

    input, select {
      -webkit-appearance: none;
      appearance: none;
      -webkit-border-radius:0px; 
      border-radius: 0;
      background-color: #fff;
      font-size: 14px;
      padding: 0 !important;
      border: none !important;
      width: 100%;
      display: block;
      &:focus-visible {
        outline: none;
      }
    }

  }
}
