
.wrap-cms-page {
  .page-body.amenities-page-body {
    padding: 74px 93px 0 57px;

    .table-responsive-custom {
      width: 100%;

      .td-3 {
        text-align: right;
      }

      tbody {
        padding-right: 0;

        .collapsed {
          .td-name, .td-description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .td-description {
          color: #2D2927;
        }

        td {
          border-bottom: solid 1px #E4E4E4 !important;

          .arrow {
            width: 11px;
            height: 15px;
          }
        }

        td:first-child {
          border: none !important;
        }

        tr {
          border: none !important;
        }
      }
    }
  }
}

.wrap-update-amenity-modal {
  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100vh !important;
    transition: none !important;

    .modal-content {
      width: 597px;
      position: static;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 35px;
    }

    .modal-header {
      padding: 0 !important;
      border: none;
    }

    .modal-footer {
      padding: 0 !important;
      border: none;

      button {
        width: auto !important;
        display: flex;
        padding: 20px 50px;
        align-items: center;
        gap: 10px;
        color: #FFF;
        text-align: center;
        font-family: 'Suisse Int';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 15.4px */
      }
    }

    button.close {
      display: none;
    }

    .modal-title {
      color: #01344B;

      /* Header - medium */
      font-family: Adobe Caslon Pro;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.1px;
      text-transform: uppercase;
    }
  }

  .wrap-modal-body {
    overflow-y: hidden;
    color: #fff;
    position: static;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 33px;

    .wrap-form-group {
      display: flex;
      flex-direction: column;
    }
    
    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-label {
      color: #767676;
      font-family: 'Suisse Int';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 15.4px */
    }

    .form-control {
      color: #1A1A1A !important;
      font-family: 'Suisse Int' !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 110% !important; /* 15.4px */
      border: none;
      border-bottom: 1px solid #1A1A1A;
      border-radius: 0;
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .close-btn {
      position: absolute;
      right: 80px;
      top: 50px;
      width: 29px;
      height: 29px;
      z-index: 110;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .error {
      font-size: 13px;
      color: red;
    }
  }
}