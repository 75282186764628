$cms-page-bg: #fff;
$cms-page-color: #000000;
$cms-input: #ffdb00;

#root {
  overflow: auto !important;
}

.wrap-cms-page {
  background: #F9F9F9;
  height: 100%;
  overflow: hidden;

  .wrap-top-nav .nav-menu ul li.active div::before {
    opacity: 1 !important;
    width: 97px !important;
  }

  .wrap-top-nav .nav-menu ul li:not(.placeholder) div::before {
    content: '';
    height: 1px;
    width: 0;
    background: #FFDB00;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 0.2s;
  }

  .wrap-top-nav {
    padding-left: 75px;
    padding-right: 75px;
    .nav-menu {
      .right {
        li:last-of-type {
          span {
            text-align: left;
            transform: translateX(-115px);
          }
          &:hover {
            span {
              &::before {
                width: 70px;
              }
            }
          }
        }
        li:first-of-type {
          span {
            text-align: left;
            transform: translateX(30px);
          }
          &:hover {
            span {
              &::before {
                width: 40px;
              }
            }
          }

          &.active {
            span {
              &::before {
                width: 40px;
              }
            }
          }
        }
      }
      .left {
        li:first-of-type {
          span {
            text-align: right;
            transform: translateX(50px);
          }
        }
        li:last-of-type {
          span {
            text-align: right;
            transform: translateX(-30px);
          }
        }
      }
    }
  }

  .wrap-top-nav {
    position: sticky;
    background: $cms-page-bg;
    color: $cms-page-color;
    height: 165px;

    .nav-menu ul li {
      opacity: 1;
    }

    .nav-menu ul li div {
      color: $cms-page-color;
      font-weight: 700;
      font-size: 14px;
      padding: 6px 12px;
    }
  }

  .cube__face--front {
    border-left: 2.16667px solid $cms-page-color;
    border-right: 3.25px solid $cms-page-color;
    border-bottom: 3.25px solid $cms-page-color;
    border-top: 1.625px solid $cms-page-color;
    transform: rotateY(0deg) translateZ(12px);
  }

  .cube__face--back {
    border-left: 1.625px solid #212529;
    border-right: 1.625px solid #212529;
    border-bottom: 3.25px solid #212529;
    transform: rotateY(180deg) translateZ(12px);
  }

  .cube__face--right {
    border-left: 3.25px solid #212529;
    border-right: 1.625px solid #212529;
    border-bottom: 3.25px solid #212529;
    transform: rotateY(90deg) translateZ(12px);
  }

  .cube__face--left {
    border-left: 3.25px solid #212529;
    border-right: 3.25px solid #212529;
    border-bottom: 3.25px solid #212529;
    border-top: 1.625px solid #212529;
    transform: rotateY(-90deg) translateZ(12px);
  }

  .cube__face--top {
    border-top: 3.25px solid #212529;
    border-right: 3.25px solid #212529;
    border-bottom: 3.25px solid #212529;
    border-left: 3.25px solid #212529;
    transform: rotateX(90deg) translateZ(12px);
  }

  .cube__face {
    background: white;
  }

  .page-header {
    max-height: 76px;
    padding: 23px 50px;
    margin-top: 80px;
    background: $cms-page-bg;
    justify-content: space-between;
    border-bottom: 1px solid var(--Header-and-Footer-Border, #E6E7E9);

    .functinalities {
      .appointments {
        display: flex;
        align-items: center;
        color: #1A1A1A;
        font-family: "Suisse Int'l";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.048px;
        text-transform: uppercase;
        padding-right: 30px;

        span {
          padding-left: 10px;
        }
      }

      .vertical-line {
        width: 1px;
        height: 30px;
        background-color: #E6E7E9;
      }

      .add-new-customer {
        display: flex;
        color: #1A1A1A;
        font-family: "Suisse Int'l";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.048px;
        text-transform: uppercase;
        padding-left: 30px;
        padding-right: 50px;

        span {
          padding-left: 10px;
          white-space: nowrap;
          display: flex;
          align-items: center;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .f-title {
      color: #1A1A1A;
      font-family: "Roos St Regis Text";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
      text-transform: none;
      margin: 0;
    }

    &__input-search {
      display: flex;
      gap: 10px;

      .form-control {
        color: #414345;
        border-right: 0;
        font-size: 14px;
        border: 0;
        border-radius: 0;
        padding: 0;
        width: 48px;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          font-family: "Suisse Int"!important;
          color: #1A1A1A;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.048px;
          text-transform: uppercase;
        }
      }
    }

    .btn {
      background: $cms-page-bg;
      color: $cms-page-color;

      &.sort-desc svg {
        transform: rotate(180deg);
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.gallery-cms {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-add-new-gallery {
        gap: 10px;
      }

      .group-btn {
        gap: 50px;
      }

      .navigator-button {
        padding: 7px 30px;
        padding-left: 0;
        margin-right: -20px;
        border: none;
        background-color: #fff;
        font-family: "Suisse Int'l";
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        color: #1A1A1A;

        &:last-of-type {
          border-right: 1px solid #E6E7E9;
        }
      }

      .input-group {
        width: auto;

        .search {
          display: flex;
        }
      }
    }
  }

  .page-body {
    padding: 74px 93px;
    background-color: #F9F9F9;

    .table > :not(caption) > * > * {
      border-bottom-width: 0px;
    }

    .table-striped > tbody > tr:nth-of-type(odd) {
      --bs-table-accent-bg: $cms-page-bg;
    }

    .table-responsive-custom {
      max-height: calc(100vh - 300px);
      overflow: overlay;

      &::-webkit-scrollbar {
        display: none;
      }

      .table-custom {
        width: 100%;

        thead {
          position: sticky;
          top: 0;
          background-color: #F9F9F9;
        }

        tbody tr .action {
          color: #1A1A1A;
          font-family: 'Suisse Int';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.052px;
          text-decoration-line: underline;
          text-transform: uppercase;
          cursor: pointer;

          &.edit {
            text-align: right;
          }
        }
      }

      thead {
        text-transform: none;
        color: #767676;
        font-family: "Roos St Regis Text";
        font-size: 20px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.1px;

        @media only screen and (max-width: 1366px) {
          .header-tenancy {
            padding-right: 30px;
          }
          .header-expiry {
            padding-right: 100px;
          }
          .header-level,
          .header-training,
          .header-precinct {
            padding-bottom: 45px;
          }
        }

        tr {
          width: "100%";
          table-layout: "auto";
          gap: 39px;
        }

        th {
          font-weight: 400;
          padding-bottom: 29px;

          .btn-edit {
            background: $cms-page-bg;
            color: $cms-page-color;
            padding: 0 10px 4px;
            font-size: 12px;
            font-weight: 700;
            line-height: 8px;
            position: relative;
            border: 0px;
            left: 10px;
            bottom: 3px;
          }
        }
      }

      tbody {
        border-left: 1px solid $cms-page-color;
        border-bottom: 1px solid $cms-page-color;
        border-right: 1px solid $cms-page-color;
        background: none;
        border: none;

        @media only screen and (max-width: 1366px) {
          height: calc(100vh - 500px);
        }

        tr {
          border-bottom: solid 1px #E4E4E4;

          td {
            border: none!important;
            font-family: "Suisse Int";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            color: #1A1A1A;

            &.loading {
              text-align: center;
              font-size: 30px;
            }

            .btn-more {
              line-height: 6px;
              padding: 0 8px 6px;
              margin-left: 30%;
              background: #f0f0ea;

              &:focus {
                box-shadow: none;
              }
            }

            padding: 13px 10px 13px 0;

            &:last-child {
              padding-right: 0px;
            }

            .custom-input {
              position: relative;
              border: none;
              background: $cms-input;
              width: 100%;
              height: 25px;
              transform: translate(-2px, 0);
              margin-bottom: 33px;
              // cursor: text;

              .error {
                font-size: 13px;
                color: red;
              }

              &:focus {
                outline: none;
              }
            }

            .form-check-input {
              height: 25px;
              border: 0;
              background-color: #e4e4da;
              width: 50px;

              &:focus {
                box-shadow: none;
                border: 0;
                background-color: "8B8B8B";
              }
            }
          }

          .clickable {
            cursor: pointer;
          }

          .text-one-line {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          td:last-of-type {
            border-right: unset;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 30px;
  }

  ::-webkit-scrollbar-track {
    background: #ffff;
    border-left: 12px solid #eeeeee;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  ::-webkit-scrollbar-thumb {
    border-left: 12px solid #d4d4d4;
    background-color: #fff;
    max-height: 100px;
    height: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-left: 12px solid #d4d4d4;
    background-color: #fff;
  }

  .page-footer {
    margin-top: 20px;

    .btn {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .btn-outline {
    border: 1px solid #000000;
    border-radius: 0px;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #ffffff;
  }

  .modal-over-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;

    .modal-notes {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 34px 40px;
      background-color: $cms-page-bg;
      max-width: 700px;
      width: 100%;
      margin: auto;

      .modal-header {
        padding: unset;
        border: none!important;

        .f-title {
          font-size: 28px;

          &::before {
            content: "";
            position: absolute;
            width: 100px;
            top: 80px;
            border-bottom: 2px solid $cms-page-color;
          }
        }
      }

      .modal-body {
        padding: 36px 134px 78px 0;
        border: unset;

        .modal-body-text {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.2;
          border: unset;
          width: 100%;
          outline: unset;
        }
      }

      .modal-footer {
        border: unset;
        display: flex;
        justify-content: flex-start;
      }

      .close-btn {
        position: absolute;
        top: 0;
        right: 0;

        border: unset;
        background-color: unset;
        color: $cms-page-color;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5;
        padding: 16px;
        line-height: 15px;
        font-weight: 300;

        &:hover {
          background-color: $cms-page-bg;
        }
      }
    }
  }
}
.wrap-content-page {
  .nav-tabs {
    border-bottom: 0;
    display: flex;
    justify-content: center;

    .nav-link {
      border: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      color: #000000;
      display: flex;
      justify-content: center;
      border-bottom: 3px solid #000000;
      &:hover {
        border-color: #BBEFFF;
      }
      &.active {
        color: #000000;
        border-color: #BBEFFF;
      }

    }
  }
}
.wrap-content-image {
  padding-bottom: 20px;
  .page-body {
    .wrap-media-list {
      &::-webkit-scrollbar {
        display: none;
      }
      overflow-y: scroll;
      max-height: calc(100vh - 300px);
      .wrap-media-item {
        height: 200px;
        .media-image {
          height: 150px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .media-details {
          .media-title {
            margin-top: 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
            margin-bottom: 13px;
          }
          .media-edit-btn {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
          }
        }
      }
    }
    .wrap-media-actions {
      .btn-add-new-media {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }
}
.wrap-content-gallery {
  padding-bottom: 20px;
  .page-body {
    .wrap-gallery-list {
      border: 1px solid #000000;
      padding: 64px 26px;
      overflow-y: scroll;
      max-height: calc(100vh - 400px);
      .wrap-gallery-item {
        height: 200px;
        .gallery-image {
          height: 150px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .gallery-details {

          .gallery-title {
            margin-top: 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
            margin-bottom: 13px;
          }
          .gallery-edit-btn {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
          }
        }
      }
    }
    .wrap-gallery-actions {
      .btn-add-new-gallery {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }
}

.select__control {
  border: none !important;
  border-bottom: 1px solid #1A1A1A !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  .select__indicator-separator {
    display: none !important;
  }

  .select__dropdown-indicator {
    svg {
      color: #1A1A1A !important;
      width: 18px !important;
      height: 18px !important;
      opacity: 0.5 !important;
    }
  }

  .select__value-container {
    padding-left: 16px;
    
    .select__single-value {
      font-family: "Suisse Int'l";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
    }
  }
}

.select__menu-portal {
  .select__option {
    background-color: white;
    color: #1A1A1A !important;
  }
}

.close-btn {
  position: absolute;
  right: 35px;
  top: 35px;
  z-index: 9999;
  cursor: pointer;
}

.search-input {
  &::placeholder {
    font-family: "Suisse Intl" !important;
    font-size: 12px !important;
  }
}