.gallery-landing {
  position: absolute;
  top: 0;
  //padding-top: 48px !important;
  flex-direction: column !important;

  .tab-wrapper {
    border-bottom: 1px solid #E6E7E9;
    background: #FFF;
    width: 100%;
    padding: 32px 50px;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;

    .tab-title {
      color: #1A1A1A;

      /* Headers */
      font-family: 'Roos St Regis Text';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }
    .tab {
      display: flex;
      flex-direction: row;

      .pv-btn-tab {
        cursor: pointer;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        .tab-item {
          color: #1A1A1A;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.048px;
          text-transform: uppercase;
        }

        .tab-border {
          width: 100%;
          background: transparent;
          height: 3px;
          margin-top: 8px;
          justify-content: center;
          align-items: center;
          display: flex;

          .line-border {
            height: 1px;
            width: 100%;
            background: #000000;
          }

          .active {
            background: #59899D;
            height: 3px;
          }
        }
      }
    }
  }

  .gallery-content {
  }
}
