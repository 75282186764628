.sst-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .sst-container {
    display: flex;
    width: 597px;
    padding: 35px;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;

    background: #FFF;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.12);

    .sst-title {
      align-self: stretch;
      color: #000;

      /* Headers */
      font-family: Roos St Regis Text;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }

    .sst-form {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-self: stretch;
      gap: 33px;

      .form-row {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
        align-self: stretch;
      }

      .form-input {
        display: flex;
        flex-direction: column;
        width: 100%;

        span {
          padding: 8px 16px 0;
          color: #767676;

          /* Form text */
          font-family: 'Suisse Int';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
          text-align: left;
        }

        span.error {
          color: #FF674C;
          font-family: "Suisse Int";
          font-size: 14px;
          font-weight: 400;
          line-height: 15.4px;
        }

        input {
          margin-top: 8px;
          padding: 8px 16px;
          border: none;
          border-bottom: solid 1px #1A1A1A;
          background: transparent;
          border-radius: 0;
          text-align: left;

          /* Form text */
          font-family: 'Suisse Int';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }

        input::placeholder {
          color: #B2B2B2;
        }

        input:focus, input:hover {
          outline: none;
          box-shadow: none;
        }

        .question-type-select {
          .select__control {
            outline-width: 0 !important;
            box-shadow: none;
            margin-top: 8px;
            border: none;
            border-bottom: 1px solid #1A1A1A;
            border-radius: 0;
            .select__placeholder,
            .select__single-value {
              color: #B2B2B2;
              font-family: 'Suisse Int';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 110%;
            }
            .select__single-value { color: #212529; }
            .select__input-container {
              margin: 0;
              padding: 0;
            }
          }
          .select__menu-portal {
            left: 40px !important;
            top: 180px !important;
          }
          .select__menu {
            border: none;
          }
          .select__option {
            color: #212529;
            font-family: 'Suisse Int';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            border-bottom: 1px solid #D3D0D1;
            border: none;
            &:hover, &.select__option--is-focused {
              background-color: #21252920;
            }
            &.select__option--is-selected {
              background: #212529;
              color: #fff;
            }
          }
          .select__indicator-separator {
            display: none;
          }
        }
      }
    }

    .sst-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      .cms-search-btn {
        border: solid 1px #1A1A1A;

        display: flex;
        padding: 20px 50px;
        align-items: center;
        gap: 10px;
        background: #1A1A1A;

        .cms-search-btn-text {
          color: #FFF;
          text-align: center;

          /* Form text */
          font-family: 'Suisse Int';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }
      }

      .cms-search-btn:active {
        background: #FFF;

        .cms-search-btn-text {
          color: #1A1A1A;
        }
      }
    }
  }
}
