.story-page{
  .leftCol{
    width: 480px;
    min-height: 702px;
    background: white;
    margin-right: 20px;
    padding: 22px 24px 0 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    .title{
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      color: #000000
    }
    .textSub{
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #000000;
      margin-top: 30px;
    }
  }
  .rightCol{
    width: calc(100vw - 180px - 480px - 20px);
    background: white;
    min-height: 702px;
    span{
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #000000;
    }
   .top-content{
      width: 100%;
      min-height: 402px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
      padding: 22px 26px;
      display: flex;
      flex-direction: column;
     .text-content{
       margin-bottom: 30px;
     }
   }
    .bottom-content{
      width: 100%;
      min-height: 281px;
      margin-top: 17px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
      padding: 30px 26px;
      display: flex;
      flex-direction: column;
    }
  }
}
