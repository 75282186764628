.experience-page{
  flex-direction: column !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .wrapper {
    height: calc(100dvh + 80px);
    padding: 30px 123px 1px 123px;
    .card-image{
      height: 313px;
      position: relative;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .bg-bottom{
        position: absolute;
        bottom: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        height: 102px;
        display: flex;
        align-items: flex-end;
        padding-left: 20px;
        span{
          padding-bottom: 20px;
          max-width: 200px;
          font-weight: 400;
          font-size: 12px;
          line-height: 116%;
          display: flex;
          align-items: flex-end;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          color: #FFFFFF;
        }
      }
    }
  }
  .btn-bottom{
    height: 44px;
    cursor: pointer;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF;
    padding: 14px 0;
    width: 346px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      color: #000;
      font-feature-settings: 'cpsp' on;
      font-family: Proxima Nova;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 16.25px */
      letter-spacing: 0.2px;
    }
  }
}
