#loading-screen {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 1;
  transition: 1s opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #fff;

  #loading-cube-wrapper {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  #wrap-cube-model {
    position: absolute;
    top: 0px;
    z-index: 1;

    &.disable-click {
      pointer-events: none;
    }
  }

  .slick-dots {
    bottom: 50px;

    li {
      margin: 0 2px;

      button:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #fff;
        }
      }
    }
  }

  .slick-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
  }

  .slick-arrow {
    display: none !important;
  }

  .wrap-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.center-center {
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    img {
      height: 100vh;
    }
  }

  .wrap-intro {
    height: calc(100vh - 160px);
    &.hide {
      display: none;
    }

    &.show {
      display: unset;
    }
  }

  &.hide {
    display: none;
  }

  .intro-content {
    z-index: 1;
  }

  .wrap-slide-loading {
    transition: all 1s;

    .wrap-content {
      position: absolute;
      bottom: 50px;
      left: 50px;
      text-align: left;

      .wrap-line {
        display: flex;
        border-top: 1px solid #fff;

        .left,
        .right {
          padding: 10px;
        }

        .left {
          width: 75%;
          border-right: 1px solid #fff;
        }

        .right {
          width: 25%;
          letter-spacing: 0.2rem;
        }
      }
    }

    &.hide {
      display: none;
      opacity: 0;
      pointer-events: none;
    }

    &.show {
      display: block;
      pointer-events: unset;
    }
  }
}

#intro-video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#intro-video-2 {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  transform: translateX(-50%);
  object-fit: fill;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#intro-img {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
}

.intro-content {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  .wrap-button {
    height: 102px;
    .explore_button {
      background-color: #000000;
      width: unset!important;
      padding: 20px 50px;
      margin-top: 50px;

      color: #FFF;
      text-align: center;
      font-family: 'SuisseIntl';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 15.4px */
    }
  }

  h1 {
    font-size: calc(2rem + 2vw);
    font-family: 'Philosopher', serif;
    margin-bottom: 1rem;
  }

  p {
    font-size: calc(1rem + 0.125vw);
    letter-spacing: 0.125rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}

.wrap-text {
  display: flex;
  flex-direction: column;
  max-width: 525px;
  .title-top{
    color: #1A1A1A;
    text-align: center;
    font-family: 'Roos St Regis Text';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-bottom: 10px;
  }
  .description{
    color: #1A1A1A;
    text-align: center;
    font-family: 'Roos St Regis Text';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 10px;
  }
}

.wrap-button {
  button {
    background: #ffffff;
    color: #000000;
    padding: 16px;
    font-weight: bold;
    border: 1px solid #000;
    font-size: 14px;
    width: 170px;
    // font-family: system-ui;
  }
}
.wrap-choose-session{
  display: flex;
  flex-direction: column;
  width: 650px;
  justify-content: space-between;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 115px;
  .header-session{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .wrap-dot{
      display: flex;
      flex-direction: row;
      margin-bottom: 34px;
      .dot{
        width: 10px;
        height: 10px;
        border-radius: 5px;
        border: solid 1px #1A1A1A;
        margin: 0 5px;
      }
      .active{
        background: #1A1A1A;
      }
    }
    .title-session{
      color: #1A1A1A;
      font-family: 'Roos St Regis Text';
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
    }
    .des-session{
      color: #1A1A1A;
      text-align: center;
      font-family: 'Roos St Regis Text';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-top: 20px;
    }
    .search-session{
      width: 100%;
      margin-top: 90px;
      .input-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        input{
          border: none;
          border-bottom: solid 1px #1A1A1A;
          background: transparent;
          border-radius: 0;
          padding-bottom: 12px;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 0;
        }
        input::placeholder{
          color: #B2B2B2;
        }
        input:focus{
          outline: none;
        }
        .btn-enter{
          width: 97px;
          height: 31px;
          padding-top: 14.5px;
          padding-bottom: 14.5px;
        }
      }
      .wrap-user-list{
        max-height: 280px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        span{
          color: #767676;
          font-family:'Suisse Int';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
          border-bottom: 1px solid #E4E4E4;
          padding: 12px 0;
          text-align: left;
          cursor: pointer;
        }
        .active{
          background: #1A1A1A;
          color: #FFFFFF;
          padding-left: 16px;
        }
      }
    }
  }
  .bottom-session{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .btn-enter{
      margin-bottom: 20px;
    }
  }

  .bottom-selected-session{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .bottom-selected-btn-group {
      button {
        margin-bottom: 0;
      }
    }

    .btn-enter-line{
      margin-right: 40px;
      margin-bottom: 20px;
    }
  }

}
