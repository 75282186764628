.search-page{
  //flex-direction: column!important;
  //.search-input{
  //  height: 80px;
  //  width: 100%;
  //  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
  //  input{
  //    height: 100%;
  //    width: 100%;
  //    border: none;
  //    padding: 16px 0 16px 23px;
  //  }
  //}
  //.wrapper{
  //  margin-top: 42px;
  //  .card-search{
  //    min-height: 277px;
  //    background: #ffffff;
  //    margin-bottom: 26px;
  //    display: flex;
  //    flex-direction: column;
  //    padding: 27px 31px 20px 26px;
  //    span{
  //      color: #000000;
  //    }
  //    .title{
  //      font-weight: 600;
  //      font-size: 24px;
  //      line-height: 31px;
  //      color: #000000
  //    }
  //    .textSub{
  //      font-weight: 400;
  //      font-size: 14px;
  //      line-height: 28px;
  //      color: #000000;
  //      margin-top: 30px;
  //    }
  //  }
  //}

  .search-page-session{
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .title-search-page{
      color: #1A1A1A;
      text-align: center;
      font-family: 'Roos St Regis Text';
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
      margin-top: 100px;
      margin-bottom: 50px;
    }
    .input-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      input{
        border: none;
        border-bottom: solid 1px #1A1A1A;
        background: transparent;
        border-radius: 0;
        padding-bottom: 12px;
      }
      input::placeholder{
        color: #B2B2B2;
      }
      input:focus{
        outline: none;
      }
      .btn-enter{
        width: 97px;
        height: 30px;
      }
    }
    .wrap-search-list{
      max-height: calc(100vh - 450px);
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      span{
        color: #767676;
        font-family:'Suisse Int';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        border-bottom: 1px solid #E4E4E4;
        padding: 12px 0;
        text-align: left;
        cursor: pointer;
      }
      .active{
        background: #1A1A1A;
        color: #FFFFFF;
        padding-left: 16px;
      }
    }
  }
}
