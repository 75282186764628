
.ReactModalPortal
{
  > div > div {
    border-radius: 0!important;
  }

  .residence-edit-modal {
    padding: 35px;
    border-radius: 0;
  }
} 

.modal.show .modal-dialog {
  width: 597px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%)!important;
  left: 50%;

  .modal-content {
    width: 597px;
    border-radius: 0;
    border: none;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.12);
    padding: 35px;
  }

  .modal-header {
    border: none;
    padding: 0px;
    margin-bottom: 50px;
    
    .modal-title {
      color: #000;
      font-family: "Roos St Regis Text";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }
  }

  .modal-body {
    padding: 0;

    .select {
      .label{
        position: relative;

        .img {
          position: absolute;
          right: 16px;
          top: 0.2rem;
        }

        p {
          padding-left: 16px;
          border-bottom: solid 1px #1A1A1A;
          color: #1A1A1A;
          font-family: "Suisse Int'l";
          font-size: 14px;
          line-height: 110%;
          padding-bottom: 8px;
          padding-top: 8px;
          font-weight: 400;
        }
      } 

      .drop-item {
        padding-left: 16px;

        .hover {
          &:hover {
            cursor: pointer;
          }

          .item-text {
            color: #1A1A1A;
            font-family: "Suisse Int'l";
            font-size: 14px;
            line-height: 110%;
            padding-top: 8px;
            font-weight: 400;

          }
        }
      }
    }

    .first-group {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 33px;
      padding-bottom: 33px;

      .custom-input, .select {
        input {
          width: 100%;
        }
      }

      .select .label p {
        margin-bottom: 0;
        padding-bottom: 12px;
      }

      .custom-group {
        display: flex;
        flex-direction: column;
      }

      .error {
        font-size: 13px;
        color: red;
      }
    }

    .middle-group {
      padding-bottom: 33px;
    }

    .last-group {
      display: grid;
      grid-column-gap: 33px;
      grid-row-gap: 33px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(2, minmax(0, 1fr));

      .custom-input, .select {
        input {
          width: 100%;
        }

        .error {
          font-size: 13px;
          color: red;
        }
      }

    }

    label {
      padding-left: 16px;
      color: #767676;
      font-family: "Suisse Int'l";
      font-size: 14px;
      line-height: 110%;
    }

    input {
      border: none;
      border-radius: 0;
      border-bottom: solid 1px #1A1A1A;
      box-shadow: none;
      outline: none;
      padding-left: 16px;
      color: #1A1A1A;
      font-family: "Suisse Int'l";
      font-size: 14px;
      line-height: 110%;
      padding-bottom: 8px;
      padding-top: 8px;

      &::placeholder {
        color: #B2B2B2;
        font-family: "Suisse Int'l";
        font-size: 14px;
        line-height: 110%;
      }

      &:hover {
        box-shadow: none;
        outline: none;
        border: none;
        border-bottom: solid 1px #1A1A1A;
      }
    }
  }

  .modal-footer {
    border: none;
    border-radius: none;
    margin: auto;
    padding: 0px;

    button {
      margin-top: 50px;
      border: none;
      width: 135px;
      height: 55px;
      background: #1A1A1A;
      color: #FFF;
      text-align: center;
      font-family: "Suisse Int";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      text-transform: uppercase;
    }
  }
}

.open {
  display: none;
}

.wrap-create-user-profile-modal {
  
  .header-customer {
    padding-top: 35px;
    color: #000;
    font-family: Roos St Regis Text;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
  }

  .bottom-booking{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .wrap-calendar {
      width: 500px;
      .sdp {
        width: 100%;
        padding: 0;
        outline: unset !important;
        box-shadow: none;
        --outline: none;
        background-color: #F0F0F0;
        border-radius: 5px;
        overflow: hidden;

        .sdp--header {
          position: relative;
          display: flex;
          align-items: center;

          .sdp--header__main {
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
            color: #ffffff;
            background-color: #000;
            border-radius: 5px;
          }

          .sdp--square-btn {
            position: absolute;
            border: none;
            background: transparent;
            box-shadow: none;

            &:first-child {
              left: 5px;
            }

            &:last-child {
              right: 5px;
            }
            svg{
              color: #ffffff;
            }
          }
        }

        .sdp--grid {
          grid-gap: 1px;
          gap: 1px;
          background-color: rgba(107, 93, 73, 0.2);
          margin: 0px;
          border-top: 1px solid rgba(107, 93, 73, 0.2);
          border-radius: 5px;

          .sdp--text {
            width: 100%;
            text-align: center;
            background-color: rgb(255,255,255);
            color: rgb(131,131,131);
          }

          .sdp--square-btn {
            width: 100%;
            border-radius: 0;
            background-color: rgb(235,235,235);
            font-size: 13px;
            height: 2.5rem;
            color: rgb(26,26,26);

            &.sdp--date-btn__selected {
              background-color: #000;
              color: #ffffff;
            }
          }

          .sdp--text__inactive{
            background-color:rgb(249,249,249);
            color: rgb(233,233,233);
          }
        }
      }
    }
    .wrap-available-time{
      .available-title{
        color: #1A1A1A;
        font-family: 'Roos St Regis Text';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */

        .time-selected{
          font-style: italic;
        }
      }
      .wrap-times {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        gap: 20px;
        margin-top: 22px;
        .time-item {
          border: 1px solid #E4E4E4;
          background: #FFF;
          padding: 10px 30px;

          &.active {
            background-color: #000;
            color: #ffffff;
          }

          &:hover {
            background-color: #000;
            color: #ffffff;
          }
        }
      }
    }
    .login-form{
      display: flex;
      flex-direction: column;
      width: 100%;

      .line-login{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .form-input {
          display: flex;
          flex-direction: column;
          width: 45%;
        }
      }

      .row {
        display: flex;
        padding-top: 33px;

        & > * {
          width: 33.3%;
        }

        span {
          padding-left: 16px;
          color: #767676;
          font-family: "Suisse Int'l";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }

        input::placeholder{
          color: #B2B2B2;
          font-family: "Suisse Int'l";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }

        input:focus{
          outline: none;
        }
      }

      .form-input{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 40px;

        .select .label {
          color: #B2B2B2;
          font-family: "Suisse Int'l";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }

        span {
          padding-left: 16px;
          color: #767676;
          font-family: "Suisse Int'l";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }

        input{
          margin-top: 8px;
          border: none;
          border-bottom: solid 1px #1A1A1A;
          background: transparent;
          border-radius: 0;
        }

        input::placeholder{
          color: #B2B2B2;
          font-family: "Suisse Int'l";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }

        input:focus{
          outline: none;
        }
      }
    }
    .txt-content{
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      .appointment-detail{
        color: #1A1A1A;
        text-align: center;
        font-family: 'Roos St Regis Text';
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 150%;
        max-width: 200px;
      }

      .txt-underline{
        color: #1A1A1A;
        text-align: center;
        font-family: 'Suisse Int';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.052px;
        text-decoration-line: underline;
        text-transform: uppercase;
      }

      .content-location{
        color: #1A1A1A;
        text-align: center;
        /* Body */
        font-family: 'Roos St Regis Text';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .wrap-continues {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 35px;

    .update-footer {
      span {
        color: #1A1A1A;
        font-family: "Suisse Int'l";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.052px;
        text-decoration-line: underline;
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      display: flex;
      justify-content: space-between;
    }
  }
}
