.vision-page{
  .bg-image-page{
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    .visibility {
      margin-top: auto;
    }
    .wrap-text{
      display: flex;
      flex-direction: column;
      margin-top: auto;
      max-width: 600px;
      .title-top{
        color: #1A1A1A;
        text-align: center;
        font-family: 'Roos St Regis Text';
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
      }
      .description{
        color: #1A1A1A;
        text-align: center;
        font-family: 'Roos St Regis Text';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
    .btn-absolute{
      margin-top: auto;
      margin-bottom: 50px;
    }
  }
}
