.wrap-customer-page {
  .card {
    border-radius: 0px;
  }

  .title {
    flex-grow: 1;
  }
}

.wrap-customer-page {
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  padding-top: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .wrap-create-customer {
    display: flex;
    flex-direction: column;
    width: 650px;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 115px;
    margin: auto;
    overflow-y: scroll;

    .header-booking {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .wrap-dot {
        display: flex;
        flex-direction: row;
        margin-bottom: 34px;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 5px;
          border: solid 1px #1A1A1A;
          margin: 0 5px;
        }

        .active {
          background: #1A1A1A;
        }
      }

      .title-booking {
        color: #1A1A1A;
        font-family: 'Roos St Regis Text';
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-bottom: 20px;
      }

      .des-booking {
        color: #1A1A1A;
        font-family: 'Roos St Regis Text';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 24px */
        max-width: 500px;
        text-align: center;
      }
    }

    .bottom-booking {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 71px;
      margin-bottom: 71px;
      gap: 46px;

      .wrap-calendar {
        width: 500px;

        .sdp {
          width: 100%;
          padding: 0;
          outline: unset !important;
          box-shadow: none;
          --outline: none;
          background-color: #F0F0F0;
          border-radius: 5px;
          overflow: hidden;

          .sdp--header {
            position: relative;
            display: flex;
            align-items: center;

            .sdp--header__main {
              width: 100%;
              padding-top: 5px;
              padding-bottom: 5px;
              color: #ffffff;
              background-color: #000;
              border-radius: 5px;
            }

            .sdp--square-btn {
              position: absolute;
              border: none;
              background: transparent;
              box-shadow: none;

              &:first-child {
                left: 5px;
              }

              &:last-child {
                right: 5px;
              }

              svg {
                color: #ffffff;
              }
            }
          }

          .sdp--grid {
            grid-gap: 1px;
            gap: 1px;
            background-color: rgba(107, 93, 73, 0.2);
            margin: 0px;
            border-top: 1px solid rgba(107, 93, 73, 0.2);
            border-radius: 5px;

            .sdp--text {
              width: 100%;
              text-align: center;
              background-color: rgb(255, 255, 255);
              color: rgb(131, 131, 131);
            }

            .sdp--square-btn {
              width: 100%;
              border-radius: 0;
              background-color: rgb(235, 235, 235);
              font-size: 13px;
              height: 2.5rem;
              color: rgb(26, 26, 26);

              &.sdp--date-btn__selected {
                background-color: #000;
                color: #ffffff;
              }
            }

            .sdp--text__inactive {
              background-color: rgb(249, 249, 249);
              color: rgb(233, 233, 233);
            }
          }
        }
      }

      .wrap-available-time {
        .available-title {
          color: #1A1A1A;
          font-family: 'Roos St Regis Text';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;

          /* 24px */
          .time-selected {
            font-style: italic;
          }
        }

        .wrap-times {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          text-align: center;
          gap: 20px;
          margin-top: 22px;

          .time-item {
            border: 1px solid #E4E4E4;
            background: #FFF;
            padding: 10px 30px;

            &.active {
              background-color: #000;
              color: #ffffff;
            }

            &:hover {
              background-color: #000;
              color: #ffffff;
            }
          }
        }
      }

      .line-login {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 33px;

        .form-input {
          display: flex;
          flex-direction: column;
          width: 308.5px;
          padding-top: 8px;

          .error {
            color: red;
            font-family: "Futura Pt Book", sans-serif;
            font-size: 13px;
            margin-top: 2px;
          }
        }

        .select__control {
          background: transparent;
        }

        .select__single-value {
          text-align: left;
          color: #B2B2B2;
        }

        .select__control {
          height: 31px;
        }

        .select__value-container {
          height: 100%;
          padding-top: 8px;
          display: flex;
          align-items: start;
        }

        .purchase-selected {
          .select__single-value {
            color: #212529;
          }
        }
      }

      .form-input {
        display: flex;
        flex-direction: column;
        width: 100%;

        span {
          color: rgba(26, 26, 26, 0.50);
          font-family: 'Suisse';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
          text-align: left;
          padding-left: 16px;
        }

        input {
          border: none;
          border-bottom: solid 1px #1A1A1A;
          background: transparent;
          border-radius: 0;
          padding: 8px 16px;
          font-size: 14px;
          line-height: 110%;
        }

        input::placeholder {
          color: #B2B2B2;
        }

        input:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .txt-content {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        .appointment-detail {
          color: #1A1A1A;
          text-align: center;
          font-family: 'Roos St Regis Text';
          font-size: 16px;
          font-style: italic;
          font-weight: 400;
          line-height: 150%;
          max-width: 200px;
        }

        .txt-underline {
          color: #1A1A1A;
          text-align: center;
          font-family: 'Suisse Int';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.052px;
          text-decoration-line: underline;
          text-transform: uppercase;
        }

        .content-location {
          color: #1A1A1A;
          text-align: center;
          /* Body */
          font-family: 'Roos St Regis Text';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }

    .wrap-btn-submit {
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      display: flex;

      .btn-enter {
        width: fit-content;
      }
    }

    .wrap-continues {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      .btn-enter {
        width: fit-content;
      }

      .left-buttons {
        display: flex;
        justify-content: center;
        align-items: center;

        .btn-enter-line {
          padding: 0;
        }  
      }
    }
  }
}
