.wrap-cms-page {
	.page-body.customers-page-body {
		.table-responsive-custom {
      thead {
				display: block;
				// padding-right: 40px;

        th {
          padding-right: 10px !important;

          &.center {
            text-align: center;
          }
        }
			}

			.collapsed {
				td {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					padding-right: 10px !important;

          &.action {
            text-align: center;
          }
				}
			}
		}
	}
}

.wrap-update-customer-modal,
.wrap-create-customer-modal,
.wrap-delete-customer-modal,
.wrap-customer-favorites-modal {
  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100vh !important;
    transition: none !important;

    .modal-content {
      width: 597px;
      position: static;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 35px;
      min-height: 189px;
    }

    .modal-header {
      padding: 0 !important;
      border: none;
    }

    .modal-footer {
      padding: 0 !important;
      border: none;

      button {
        width: auto !important;
        display: flex;
        padding: 20px 50px;
        align-items: center;
        gap: 10px;
        color: #FFF;
        text-align: center;
        font-family: 'Suisse Int';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 15.4px */
      }
    }

    button.close {
      display: none;
    }

    .modal-title {
      color: #01344B;

      /* Header - medium */
      font-family: Adobe Caslon Pro;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.1px;
      text-transform: uppercase;
    }
  }

  .wrap-modal-body {
    overflow-y: hidden;
    color: #fff;
    position: static;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 33px;

    .confirm {
      color: #1A1A1A;
      font-family: 'Roos St Regis Text';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }

    .wrap-form-group {
      display: flex;
      flex-direction: row;
      gap: 33px;

      .form-group {
          flex: 1 0 0;
      }
    }
    
    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-label {
      color: #767676;
      font-family: 'Suisse Int';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 15.4px */
    }

    .form-control {
      color: #1A1A1A !important;
      font-family: 'Suisse Int' !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 110% !important; /* 15.4px */
      border: none;
      border-bottom: 1px solid #1A1A1A;
      border-radius: 0;
      box-shadow: none;

      & * {
        background-color: white;
        width: 100px;
      }
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .close-btn {
      position: absolute;
      right: 80px;
      top: 50px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }

  }

  .error {
    font-size: 13px;
    color: red;
  }
}

.wrap-delete-customer-modal {
  .modal-dialog {
    .modal-content {
      width: 418px !important;
    }
  }
}

.wrap-update-customer-modal {
  .modal-dialog {
    .modal-footer {
      display: flex;
      justify-content: space-between;
      margin: 0 !important;
    }

    button.delete {
      background: none !important;
      color: #1A1A1A !important;
      font-size: 13px !important;
      line-height: normal !important;
      letter-spacing: 0.052px !important;
      text-decoration-line: underline;
      text-transform: uppercase;
      padding-left: 0;
      margin-left: 0;
    }
  }
}

.wrap-customer-favorites-modal {
  .wrap-loading-icon {
    bottom: 42%;
    left: 48%;
    animation-duration: unset;
    position: absolute;

    .ldio-h0h1lsshjn {
      div {
        background: #000;
      }
    }
  }

  .modal-dialog {
    color: black !important;

    .modal-content {
      width: 1180px !important;

      .loading {
        height: 100px;
      }

      .content {
        color: #1A1A1A;
        font-family: "Suisse Int'l" !important;
        font-size: 24px;

        table {
          tbody {
            padding-bottom: 38px;
          }

          th {
            color: #767676;
            font-family: 'Roos St Regis Text';
            font-size: 20px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.1px;
            padding-bottom: 30px;
          }

          td {
            color: #1A1A1A;
            font-family: "Suisse Int'l";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 15.4px */
            padding: 12px 0px;
          }
        }

        .gallery {
          display: flex;
          flex-direction: row;
          gap: 18px;
          padding-top: 50px;
          border-top: 1px solid #E4E4E4;

          .gallery-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;

            img {
              width: 358px;
              height: 225px;
              object-fit: cover;
            }

            span {
              color: #2D2927;
              text-align: center;
              font-family: "Suisse Int'l";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 110%; /* 15.4px */
            }
          }
        }
      }
    }
  }
}