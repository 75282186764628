.wrap-gallery-primary {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #000000;

  .slick-slider {
    max-height: 100vh;
    overflow: hidden;

    .gallery-item {
      width: 100vw;
      height: 100vh;
      position: relative;
      display: flex !important;
      justify-content: center;
      align-items: center;
      .gallery-hotspot {
        position: absolute;
        z-index: 100;
        cursor: pointer;
        width: 30px;
      }
      img {
        width: 80vw;
        height: 95vh;
        object-fit: contain;
      }
      .left-15 {
        left: 15vw;
      }
      .right-15 {
        right: 15vw;
      }
    }
  }
  .slick-dots {
    display: none !important;
    bottom: 70px;
    li {
      margin: 0 2px;

      button:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #fff;
        }
      }
    }
  }
  .btn-gallery {
    display: block;
    position: absolute;
    top: 50%;
    cursor: pointer;
  }
  .btn-gallery.prev-slide {
    left: 25px;
    color: rgba(255, 255, 255, 0.6);
    img {
      transform: rotate(180deg);
      width: 50px;
    }
  }
  .btn-gallery.next-slide {
    right: 25px;
    color: rgba(255, 255, 255, 0.6);
    img {
      width: 50px;
    }
  }
  .img-close {
    position: absolute;
    top: 35px;
    right: 35px;
    width: 29px;
    height: 29px;
    cursor: pointer;
  }
}
