.teamPage{
  flex-direction: column !important;

  .wrapper {
    padding: 79px 123px;
    padding-bottom: 0px;
    height: 100%;
  }

 .card-image{
   height: 313px;
   position: relative;
   cursor: pointer;
   img{
     width: 100%;
     height: 100%;
     object-fit: cover;
   }
   .bg-bottom{
     position: absolute;
     bottom: 0;
     width: 100%;
     background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
     display: flex;
     padding-left: 20px;
     padding-bottom: 15px;
     flex-direction: column;
     span{
       color: #FFF;
       font-family: 'Roos St Regis Text';
       font-size: 18px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
       letter-spacing: 0.23px;
     }
     span:last-child{
       font-family: 'Proxima Nova';
       font-size: 12px;
       line-height: 116%; /* 13.92px */
       letter-spacing: 0.3px;
       text-transform: uppercase;
       margin-top: 4px;
     }
   }
 }
}
