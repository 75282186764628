.renders-content {
  .card-renders {
    background-color: #FFFFFF;
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.25));
    height: 130px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

    .title {
      position: absolute;
      bottom: 15px;
      left: 15px;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 116%;
      letter-spacing: .3px;
      text-transform: uppercase;
      color: #fff;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .infinity {
    background-color: #E3E3E3;
  }
}

.films-content {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 123px;

  .card-films {
    width: 100%;
    height: 334px;
    padding: 70px;
    background-color: #000;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

    .card-films-sub {
      color: #FFF;
      font-family: 'SuisseIntl';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      text-transform: uppercase;
    }

    .card-films-title {
      color: #FFF;
      font-family: 'Roos St Regis Text';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }

    img {
      margin-top: 50px;
      width: 75px;
      cursor: pointer;
    }
  }

  .infinity {
    background-color: #E3E3E3;
  }

  .wrap-video-film {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    padding: 128px 0;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

  }
}

.floor-plans-content {
  width: 100%;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  position: relative;

  .content-floor-header {
    margin-top: 50px;
    text-align: center;
  }

  .content-floor-title {
    color: #1A1A1A;
    text-align: center;
    font-family: 'Roos St Regis Text';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .content-plans {
    .header-content {
      margin-top: 50px;
      margin-bottom: 25px;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      height: fit-content;

      .content-plans-title {
        color: #1A1A1A;
        font-family: Roos St Regis Text;
        font-size: 28px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.14px;
      }

    }

    .content-plans-container {
      width: 675px;
      margin: 0 auto 50px auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 26px 15px;

      .content-plans-item {
        cursor: pointer;
        display: flex;
        width: 100px;
        height: 100px;
        padding: 3px 6px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background: #FFF;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
      }

      .content-plans-text {
        cursor: pointer;
        color: #1A1A1A;
        font-family: 'SuisseIntl';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 15.4px */
      }
    }
  }

  .body-content {
    margin-top: 50px;

    .body-row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      gap: 100px;
    }

    .car-floor {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      .car-floor-title {
        color: #767676;
        text-align: center;
        font-family: Roos St Regis Text;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }

      .car-floor-content {
        color: #767676;
        text-align: center;
        font-family: Roos St Regis Text;
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;

      }
    }

    .car-floor.isSelected {
      .car-floor-title, .car-floor-content {
        color: #1A1A1A;
      }
    }

  }

  .btn-floor {
    position: absolute;
    bottom: -35px;
    right: -35px;
    cursor: pointer;
  }

  .content-plans {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.infinity {
  background-color: #E3E3E3;
}

.modal-floor {
  .header-content {
    margin-top: 0;
  }

  .body-content {
    padding: 0 20px;

    .car-floor {
      width: 50px;
      height: 50px;
      border-radius: 25px;

      span {
        font-size: 30px;
      }
    }

    .isSelected {
      background-color: #000000;

      span {
        color: #FFFFFF;
      }
    }
  }
}

.view-plans {
  width: 100%;
  height: 100%;

  .slider-floor-plan {
    .img-plans-content {
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .slick-slider {
    .slick-list {
      height: calc(100vh - 140px);
    }

    .slick-arrow {
      width: 50px;
      height: 50px;
    }

    .slick-arrow.slick-next {
      position: absolute;
      z-index: 99;
      right: 0;
      top: 50vh;
    }

    .slick-arrow.slick-prev {
      position: absolute;
      z-index: 99;
      left: 0;
      top: 50vh;
    }

  }
}

.media-images-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 120px;

  .media-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    .media-row {
      width: 100%;
      display: flex;
      gap: 15px;

      .media-item {
        position: relative;
        flex: 1;
        flex-basis: 0;
        cursor: pointer;
  
        .media-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .media-item-text {
        position: absolute;
        left: 10px;
        bottom: 10px;
  
        color: #FFF;
        font-family: Roos St Regis Text;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.06px;
      }
    }
  }
}

.media-images-gallery {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.hotspot-wrapper {
  width: 168px;
  height: 52px; 
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #1A1A1A;
  z-index: 10000;
  cursor: pointer;
  font-family: Roos St Regis Text;
}

.hotspot-wrapper:last-of-type {
  margin-bottom: 0;
}

.loading-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transition: translate(-50%, -50%);
  color: white;
  font-size: 30px;
}
