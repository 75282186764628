.sessionPage {
  padding: 0 !important;

  main {
    background-color: #F5F5F5;
  }

  .info-user {
    width: 100%;
    padding-top: 117px;
    padding-bottom: 117px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 80px;
    max-height: 331px;

    .title-name {
      color: #1A1A1A;
      /* Headers */
      font-family: 'Roos St Regis Text';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
      max-width: 415px;
      text-transform: uppercase;
    }

    .title-des {
      color: #1A1A1A;
      text-align: center;
      /* Body */
      font-family: 'Roos St Regis Text';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      max-width: 415px;
    }
  }
  .btn-end-session {
    background-color: #000;
    color: #FFFFFF;
    padding: 0.84rem 2.14rem;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "Roos St Regis Text";
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  }
  .img-list {
    display: flex;
    flex-direction: column;
    margin-top: 100px;

    img {
      object-fit: cover;
      width: 100vw;
    }
  }

  .footer {
    padding: 52px 50px;
    margin-bottom: 10px;
    background-color: #FFFFFF;
  }

  .close-view {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #FFFFFF;
    text-align: center;

    span {
      color: #1A1A1A;
      text-align: center;
      font-family: Suisse Intl;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.052px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .unit-section-wrap {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .divider {
    width: 100%;
  }

  .wrap-unit-detail {
    padding: 98px;
    margin: 0;
    width: 100%;
    max-width: 1366px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1180px) {
      padding: 20px;
    }

    &.wrap-1 { gap: 100px }
    &.wrap-2 { gap: 50px }

    .wrap-left-1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 50px;

      .unit-floorplan {
        width: 500px;
        height: 312.217px;
      }
    }
    .wrap-right-1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 22.5px;

      .unit-gallery-0 {
        width: 570px;
        height: 355.661px;
      }
      .unit-gallery-1 {
        width: 570px;
        height: 380px;
      }
    }
    .wrap-left-2 {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 22.5px;

      .unit-gallery-0,
      .unit-gallery-1 {
        max-width: 560px;
        max-height: 373.771px;
      }
    }
    .wrap-right-2 {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 50px;

      .unit-floorplan {
        width: 560px;
        height: 349.247px;
      }
    }

    .unit-name {
      color: #1A1A1A;
      font-family: Roos St Regis Text;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
      margin-bottom: 35px;
    }

    .unit-description {
      color: #1A1A1A;
      font-family: Roos St Regis Text;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      text-transform: uppercase;

      .interior {
        margin-top: 20px;
      }

      .exterior {
        margin-top: 20px;
      }
    }
  }

  .no-data {
    height: calc(100dvh - 160px - 331px - 216px); // minus top nav, bottom nav, header, footer
    min-height: 147px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-name {
      color: #c1c1c1;
      font-family: 'Roos St Regis Text';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
      max-width: 415px;
      text-transform: uppercase;
    }
  }

  .wrap-session-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    * {
      color: #1A1A1A;
      font-family: Roos St Regis Text;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    ul {
      list-style: none;
    }
  }
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gap-6 {
  gap: 1.5rem;
}
