.cms-search-wrapper {
  padding-top: 80px;
  background: #F9F9F9;
  height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .cms-search-header {
    padding-top: 114px;
    text-align: center;

    .cms-search-header-text {
      color: #1A1A1A;
      text-align: center;

      /* Main page header */
      font-family: 'Roos St Regis Text';
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
    }
  }

  .cms-search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 20px 0 20px;
    padding-bottom: 100px;

    .cms-search-body {
      display: flex;
      width: 650px;
      align-items: flex-end;

      .cms-search-input-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 0 0;

        .cms-search-input {
          display: flex;
          flex-direction: column;
          align-items: stretch;

          input {
            padding: 8px 16px 8px 16px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 8px;

            color: #1A1A1A;

            /* Form text */
            font-family: 'Suisse Int';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 15.4px */

            border: 0;
            background-color: transparent;
          }

          input::placeholder {
            color: #1A1A1A;

            /* Form text */
            font-family: 'Suisse Int';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 15.4px */
          }

          input:focus {
            outline: none;
          }
        }

        .cms-search-line {
          width: 100%;
          height: 1px;
          background: #1A1A1A;
        }
      }

      .cms-search-btn {
        border: solid 1px #1A1A1A;

        display: flex;
        height: 31px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: #1A1A1A;

        .cms-search-btn-text {
          color: #FFF;
          text-align: center;

          /* Form text */
          font-family: 'Suisse Int';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }
      }

      .cms-search-btn:hover {
        background: #FFF;

        .cms-search-btn-text {
          color: #1A1A1A;
        }
      }
    }

    .cms-search-result {
      margin-top: 50px;
      display: flex;
      width: 650px;
      flex-direction: column;
      align-items: flex-start;

      .cms-search-result-item {
        display: flex;
        padding: 12px 0px;
        align-items: flex-start;
        align-self: stretch;
        border-bottom: 1px solid #E4E4E4;

        span {
          color: #1A1A1A;

          /* Form text */
          font-family: 'Suisse Int';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }
      }

      .cms-search-no-result {
        display: flex;
        padding: 12px 0px;
        align-items: flex-start;
        align-self: stretch;

        span {
          color: #1A1A1A;

          /* Form text */
          font-family: 'Suisse Int';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 15.4px */
        }
      }
    }
  }

}
