p {
  margin: 0 !important;
}
.list-item{
  &-gallery-details {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .gallery-title {
      font-size: 14px;
      line-height: 1.1;
      font-weight: 400;

      .gallery-title-name {
        color: #2D2927;
        font-family: "Suisse Int'l";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 15.4px */
      }

      .gallery-title-desc {
        margin-top: 10px !important;
        color: #B2B2B2;
        letter-spacing: 1px;
        font-family: "Suisse Int'l";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 15.4px */
      }

      .gallery-title-edit {
        display: flex;
        align-items: center;
      }
    }
  }

  &-gallery-image-container{
    position: relative;
    height: 225px;
    width: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

.form-switch {
  margin-top: -1px !important;
}

.form-switch .form-check-input {
  background-color: rgba(211, 208, 209, 0.35);
  width: 3rem;
  height: 25px;
  border: none !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
  box-shadow: none;
  outline: none;
  margin-right: 4px;

  &:checked {
    background-color: #FFD494 !important;
  }

  &:focus {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
  }
}