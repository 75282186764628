@import "../../common";

.wrap-sidenav {
  position: absolute;
  z-index: 111;
  width: fit-content;
  height: calc(100dvh - 220px);
  @media only screen and (max-width: 1366px) {
    height: calc(100dvh - 220px);
  }
  top: 105px;
  left: 0;
  color: #000;
  .sidenav {
    width: 240px;
  }

  > div {
    height: 100%;
  }

  .sidenav__heading {
    padding: 0;
    margin-bottom: 10px;

    h2 {
      color: #1a1a1a;
      /* Headers */
      font-family: "Roos St Regis Text";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }
  }

  .sidenav__body {
    justify-content: start;
    line-height: 2.188;
  }

  .sidenav--dark {
    background: rgba(#343434, 0.9);

    .list-group-item {
      &:hover {
        opacity: 1;
      }
    }
    .list-group-heading {
      color: #ababab;
    }
  }

  .wrap-btn-detail .list-group .list-group-item,
  .list-group-item {
    padding: 0 0.75rem;
  }
  .list-group-item {
    font-weight: 400;
    color: #767676;
    font-family: "Roos St Regis Text";
    font-size: 16px;
    font-style: normal;
    line-height: 150%;
    margin-bottom: 13px;
    &.list-group-heading {
      font-weight: 500;
    }
    &.active {
      color: #1A1A1A;
    }
  }
  .list-group-heading {
    cursor: initial;
    flex-basis: 100%;
    letter-spacing: 0.12em;
  }
  .list-group-horizontal .list-group-item:not(.list-group-heading) {
    flex-basis: 30%;
  }
  #filter {
    position: relative;
    padding-top: 35px;
    background-color: $white;
    .sidenav__body {
      padding-right: 5px;
    }
    margin-right: 10px;
    .filter-group {
      &.aspect {
        font-weight: 400;
        .name {
          font-size: 13px;
        }
        .description {
          font-size: 12px;
          color: #797979;
        }
      }
      .heading {
        color: #1a1a1a;
        font-family: " Roos St Regis Text";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      .list-group {
        .list-group-item {
          border: 1px solid #e4e4e4;
          color: #767676;
          font-family: "Suisse Int";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
          padding: 5px 10px;
          &.border-none {
            border: none;
          }
          &.active {
            color: #E4E4E4 !important;
            background-color: #1A1A1A !important;
          }
        }
      }
      .input-with-label {
        position: relative;
        text-align: center;
        .form-control {
          height: 40px;
          font-size: 11px;
        }
        .input-label {
          font-size: 10px;
          position: absolute;
          top: -4px;
          left: 33%;
          color: #797979;
        }
      }
    }
    .wrapper-button {
      position: absolute;
      bottom: 26px;
      padding-left: 0.75rem;
      width: 100%;
      left: 0;
      z-index: 100;
      flex-direction: column;
      background-color: #fff;
      .btn-bottom {
        cursor: pointer;
        span {
          color: #767676;
          font-family: "Suisse Int";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.052px;
          text-decoration-line: underline;
          text-transform: uppercase;
        }
        .txt-active {
          color: #1a1a1a;
        }
      }
      .active-btn {
        pointer-events: none;
      }
      .unActive-btn {
        pointer-events: visible;
      }
    }
  }
  #lots {
    background-color: $white;
    padding: 35px 1rem;
    .wrap-show-filter-btn {
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 100;
      bottom: 6px;
      padding-bottom: 20px;
      .btn-bottom {
        cursor: pointer;
        padding-left: 35px;
        span {
          color: #767676;
          font-family: "Suisse Int";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.052px;
          text-decoration-line: underline;
          text-transform: uppercase;
        }
        .txt-active {
          color: #1a1a1a;
        }
      }
    }
    .list-group-item {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.wrap-sidenav--right {
  left: unset;
  right: 0;
}

.wrap-btn-detail {
  width: 100%;
  text-align: left;
  color: #fff;

  .list-group {
    .list-group-item {
      text-transform: uppercase;
      text-decoration: underline !important;
      font-weight: 600;
      font-size: 14px;
      padding: 1.25rem 2rem;
      cursor: pointer;
      opacity: 1;
    }
  }
}

.wrap-btn-show-filter {
  font-family: "Futura Pt";
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  font-weight: 300;
  left: 0;
  right: 0;
  bottom: -2rem;
  z-index: 1120;

  p {
    margin: 0;
    b {
      color: #fff;
      text-decoration: underline;
    }
  }
}

#sidenavDetail {
  width: 285px;
  background-color: $white;
  padding-top: 35px;
  padding-bottom: 34px;
  .sidenav {
    &__body {
      .list-group-item {
        .txt-content {
          color: #767676;
          font-family: "Roos St Regis Text";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          text-transform: uppercase;
          text-decoration-line: none;
        }
      }
      .btn{
        color: #1a1a1a;
        font-family: "Suisse Int";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.052px;
        text-decoration-line: underline;
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 0;
        text-align: left;
      }
      & > .list-group .list-group-item {
        cursor: initial;
      }
    }
  }
  .sidenav__heading h2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 24px;
  }
}

.accordion-filter {
  .list-group-heading {
    font-size: 12px;
    &::after {
      content: "+";
      display: inline-block;
      margin-left: 0.5rem;
      margin-top: -3px;
    }
    &.open::after {
      content: "-";
    }
  }
}

.scroll-unit {
  overflow-y: scroll;
  height: 95%;
}
.scroll-filter {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 88%;
}

.wrap-nav {
  position: absolute;
  top: 0px;
  z-index: 1000;
  height: 80px;
  background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25.5px 50px;
  align-items: center;
  font-family: 'Roos St Regis Text';

  .unit-name {
    font-size: 28px;
    font-weight: 400;
    color: #1A1A1A;
  }

  .close-button {
    font-size: 12px;
    font-weight: 400;
    font-family: "Futura Pt Book";
    cursor: pointer; 
  }
}

.close-button-down {
  cursor: pointer; 
  position: absolute; 
  bottom: 100px;
  right: 20px; 
  z-index: 1000;
  color: white; 
  font-size: 14px; 
  font-weight: 600; 
  text-decoration: underline;
}
