.wrap-floor-plan-gallery {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 119;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: rgb(246,246,246);

  .slick-slider {
    max-height: 100vh;
    overflow: hidden;

    .slick-slide {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .floorplan-item {
      width: 100vw;
    }
  }

  .react-transform-wrapper {
    height: 100% !important;
    width: 100vw !important;

    .floorplan-item {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: #F9F8F9;
  
      &.img-not-found {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
  
    .slick-slide {
      border: none;
    }
  }
}

.wrap-list-floor {
  .list-group {
    position: absolute;
    width: 100%;
    bottom: 0;

    .list-group-item {
      text-decoration: underline;
      font-size: 14px;
      color: #000;
      opacity: 1;
      font-weight: 300;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
    }
  }
}

.wrap-zoom-btn {
  position: absolute;
  bottom: 100px;
  z-index: 1000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    cursor: pointer;
  }
}

.wrap-close-btn{
  position: absolute;
  bottom: 110px;
  z-index: 1000;
  width: 100%;
  padding: 0 36px;
  display: flex;
  justify-content: space-between;
  div{
    cursor: pointer;
    span{
      color: #1A1A1A;
      font-family: "Suisse Int'l";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.048px;
      text-transform: uppercase;
    }
    img {
      margin-right: 10px;
    }
    .rotate{
      transform: rotate(180deg);
    }
    .txt-white{
      color: #ffffff;
    }
  }
}
